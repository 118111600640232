import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import WorkIcon from '@mui/icons-material/Work';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { User } from "../models/User.model";
import { Error } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import VerifiedIcon from '@mui/icons-material/Verified';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { userHasPermission } from '../services/User.service';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TableChartIcon from '@mui/icons-material/TableChart';
interface NavigationListItemProps {
    appUser?: User
} 

const NavigationListItems = (props: NavigationListItemProps) => {
    const location = useLocation();
    return (
        <React.Fragment>
            <ListItemButton component={Link} to='/' selected={location.pathname === '/'}>
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItemButton>
            {userHasPermission("Account_List_View", props.appUser) && (
                <ListItemButton component={Link} to='/accounts' selected={location.pathname.toLowerCase().indexOf("/accounts") > -1}>
                    <ListItemIcon>
                        <AccountBalanceIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Accounts">Accounts</ListItemText>
                </ListItemButton>
            )}
            {userHasPermission("Survey_List_View", props.appUser) &&
                <ListItemButton component={Link} to="/assessments" selected={location.pathname.toLowerCase().indexOf("/assessments") > -1}>
                    <ListItemIcon>
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Assessments" />
                </ListItemButton>
            }
            {userHasPermission("SurveyType_List_View", props.appUser) && 
                <ListItemButton component={Link} to='/assessmentTypes' selected={location.pathname.toLowerCase().indexOf("/assessmenttypes") > -1}>
                    <ListItemIcon>
                        <FormatListBulletedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Assessment Types"/>
                </ListItemButton>
            }
            {userHasPermission("Case_List_View", props.appUser) && (
                <ListItemButton component={Link} to='/cases' selected={location.pathname.toLowerCase().indexOf("/cases") > -1}>
                    <ListItemIcon>
                        <Error />
                    </ListItemIcon>
                    <ListItemText primary="Cases" />
                </ListItemButton>
            )}
            {userHasPermission("Document_List_View", props.appUser) && (
                <ListItemButton component={Link} to='/documents' selected={location.pathname.toLowerCase().indexOf("/documents") > -1}>
                    <ListItemIcon>
                        <FileCopyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Documents" />
                </ListItemButton>
            )}
            {userHasPermission("EmailTemplate_List_View", props.appUser) && (
                <ListItemButton component={Link} to='/emailtemplates' selected={location.pathname.toLowerCase().indexOf("/emailtemplates") > -1}>
                    <ListItemIcon>
                        <MailOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Email Templates">Email Administration</ListItemText>
                </ListItemButton>
            )}
            {userHasPermission("Import_List_View", props.appUser) && 
            <ListItemButton component={Link} to="/imports" selected={location.pathname.toLowerCase().indexOf("/imports") > -1}>
                <ListItemIcon>
                    <CloudUploadIcon/>
                </ListItemIcon>
                <ListItemText primary="Imports">Imports</ListItemText>
            </ListItemButton>
            }
            <ListItemButton component={Link} to="/insights" selected={location.pathname.toLowerCase().indexOf("/insights") > -1}>
                <ListItemIcon>
                    <VerifiedIcon />
                </ListItemIcon>
                <ListItemText primary="Insights" />
            </ListItemButton>
            {userHasPermission("ReleaseNote_List_View", props.appUser) && (
            <ListItemButton component={Link} to="/releasenotes" selected={location.pathname.toLowerCase().indexOf("/releasenotes") > -1}>
                <ListItemIcon>
                    <StickyNote2Icon />
                </ListItemIcon>
                <ListItemText primary="Release Notes" />
            </ListItemButton>
            )}
            {userHasPermission("SurveyBuilder_Detail_Edit", props.appUser) && (
                <ListItemButton component={Link} to={`${process.env.REACT_APP_INTERAL_BASE_SURVEY_URL}`} target='_blank'>
                    <ListItemIcon>
                        <BuildCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Survey Builder" />
                </ListItemButton>
            )}
            {userHasPermission("AssessmentReports_Detail_View", props.appUser) && (
            <ListItemButton component={Link} to="/aars" selected={location.pathname.toLowerCase().indexOf("aars") > 0}>
                <ListItemIcon>
                    <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItemButton>
            )}
            {userHasPermission("UserAccount_List_View", props.appUser) && (
                <ListItemButton component={Link} to='/users' selected={location.pathname.toLowerCase().indexOf("/users") > -1}>
                    <ListItemIcon>
                        <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItemButton>
            )}
            {userHasPermission("UserRole_List_View", props.appUser) && (
                <ListItemButton component={Link} to='/userroles' selected={location.pathname.toLowerCase().indexOf("/userroles") > -1}>
                    <ListItemIcon>
                        <WorkIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Roles" />
                </ListItemButton>
            )}
            {userHasPermission("Validation_List_View", props.appUser) && (
                <ListItemButton component={Link} to="/validations" selected={location.pathname.toLowerCase().indexOf("/validations") > -1}>
                    <ListItemIcon>
                        <AssuredWorkloadIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Validations"/>
                </ListItemButton>
            )}
        </React.Fragment>
    );
}

export default NavigationListItems;
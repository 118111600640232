import { BarItem } from "@mui/x-charts";
import { MostRecentPublishedScoresRequest } from "../../../../models/MostRecentPublishedScoresRequest.model";
import NumericDropDownModel from "../../../../models/NumericDropDown.model";
import moment from "moment";

export interface LatestPublishedScoresDetailsProps {
    queryOptions: LatestCompletedAssessmentDetailsSearch,
    onLoad: (isLoading: boolean) => void,
    axisLable?: (itemL:BarItem) => string|undefined,
    updateQueryOptions?: (options:MostRecentPublishedScoresRequest) => void
};

export interface StageAchievmentModel {
    stage: number,
    percentageAchieved: number,
    [key: string]: number
};

export interface AverageStagePercentAchievementModel {
    facilitiesAssessed: number,
    averageStageAchievement: number,
    stageAchievements: StageAchievmentModel[]
};

export const CreateDefaultAverageStagePercentageAchievementModel = ():AverageStagePercentAchievementModel => {
    return  {
        facilitiesAssessed: 0,
        averageStageAchievement: 0,
        stageAchievements: [
            {
                stage: 1,
                percentageAchieved: 0
            },
            {
                stage: 2,
                percentageAchieved: 0
            },
            {
                stage: 3,
                percentageAchieved: 0
            },
            {
                stage: 4,
                percentageAchieved: 0
            },
            {
                stage: 5,
                percentageAchieved: 0
            },
            {
                stage: 6,
                percentageAchieved: 0
            },
            {
                stage: 7,
                percentageAchieved: 0
            },
        ]
    }
};

export interface StageDistrbution {
    stage: number,
    total: number,
    [key: string]: number
};

export interface StageAchievedDistributionModel {
    stageDistributions: StageDistrbution[]
};

export const CreateDefaultStageAchievedDistributionModel = ():StageAchievedDistributionModel => {
    return {
        stageDistributions: [
            {
                stage: 0,
                total: 0
            },
            {
                stage: 1,
                total: 0
            },
            {
                stage: 2,
                total: 0
            },
            {
                stage: 3,
                total: 0
            },
            {
                stage: 4,
                total: 0
            },
            {
                stage: 5,
                total: 0
            },
            {
                stage: 6,
                total: 0
            },
            {
                stage: 7,
                total: 0
            },
        ]
    }
};

export interface FocusAreaAchievement {
    focusArea: string,
    stageAchieved: number,
    [key: string]: number | string
}

export interface AverageFocusAreaStageAchievementModel {
    focusAreaAchievements: FocusAreaAchievement[]
};

export const CreateDefaultAverageFocusAreaStageAchievementModel = ():AverageFocusAreaStageAchievementModel => {
    return {
        focusAreaAchievements: []
    }
};

export interface FocusAreaStage {
    focusArea: string,
    // scores: number[],
    stage01: number,
    stage23: number,
    stage45: number,
    stage67: number,
    [key: string]: number | string
}

export interface FocusAreaStageAchievementByFacilityModel {
    focusAreaAchievements:FocusAreaStage[]
};

export const CreateDefaultFocusAreaStageAchievementByFacilityModel = ():FocusAreaStageAchievementByFacilityModel => {
    return {
        focusAreaAchievements: []
    }
};

export interface StagePercentAchievedByFocusAreaModel {
    focusArea: string,
    stage1: number|null,
    stage2: number|null,
    stage3: number|null,
    stage4: number|null,
    stage5: number|null,
    stage6: number|null,
    stage7: number|null,
    [key: string] : number|string|null
};

export interface AssessmentsCompletedByRegionModel {
    region: string,
    total: number,
    model: string,
    [key:string]: number|string
};

export interface AssessmentsCompletedByRegionDataViz {
    assessmentsCompletedByRegions: AssessmentsCompletedByRegionModel[],
    models: string[],
    regions: string[]
}

export const CreateDefaultAssessmentsCompletedByRegionDataViz = (): AssessmentsCompletedByRegionDataViz => {
    return {
        assessmentsCompletedByRegions: [],
        models: [],
        regions: []
    }
};
export interface AssessmentsCompletedByCountryModel {
    country: string,
    total: number,
    model: string,
    [key: string]: number|string
};

export interface AssessmentsCompletedByCountryDataViz {
    assessmentsCompletedByCountries: AssessmentsCompletedByCountryModel[],
    countries: string[],
    models: string[]
};

export interface LatestCompletedAssessmentDetailsSearch {
    modelID: NumericDropDownModel|null,
    regionIDs: NumericDropDownModel[],
    countryIDs: NumericDropDownModel[],
    completionDateRange: [moment.Moment|null, moment.Moment|null],
};

export const CreateDefaultLatestCompletedAssessmentDetailsSearch = ():LatestCompletedAssessmentDetailsSearch => {
    return {
        modelID: {label: "EMRAM 2022", value: 12},
        regionIDs: [], 
        countryIDs: [],
        completionDateRange: [moment().add(-3, "years").add(-3, "months"), null]
    }
};

export interface AssessmentsCompletedByCountryDataViz {
    assessmentsCompletedByCountries: AssessmentsCompletedByCountryModel[],
    countries: string[],
    models: string[]
};

export const CreateDefaultAssessmentsCompletedByCountryDataViz = ():AssessmentsCompletedByCountryDataViz => {
    return {
        assessmentsCompletedByCountries: [],
        countries: [],
        models: []
    }
}
import { FormControl, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { DataGridPro as DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro';
import { forEach } from 'lodash';
import BaseLayout from '../../layout/BaseLayout.component';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useApiAuthorization } from '../../components/authorization/ApiAuthorizationProvider.component';
import { useNavigate, useLocation } from 'react-router-dom';
import { GetEmailTemplateListColumns, SearchEmailTemplates } from './EmailTemplateList.service';
import { GetEmailTemplateDetail } from './EmailTemplateDetail.service';
import { CreateDefaultEmailTemplateListSearchRequest, EmailTemplateDetailModel, EmailTemplateSearchRequest } from './EmailTemplate.models';
import EmailTemplateDialog from './EmailTemplateDialog.component';
import RightMenu from "../../layout/RightMenu.component";
import NoFilterToolBar from "../../components/dataGrid/NoFilterToolBar.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import LanguageDropDownMulti from "../../components/formControls/LanguageDropDownMulti.component";
import { GetAssessmentTypeTypeAheadSearch } from "../../services/AssessmentType.service";
import { GetEmailTemplateTypeAheadMultiSearch } from "../../services/EmailTemplate.service";

const EmailTemplateList = () => {
    const {appUser} = useApiAuthorization();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplateDetailModel>(Object);
    const [selectedEmailTemplateID, setSelectedEmailTemplateID] = useState<number|null>(null);
    const [showEmailTemplateDialog, setShowEmailTemplateDialog] = useState<boolean>(false);
    const [rowData, setRowData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const getEmailTemplateCallback = useCallback((emailTemplateID: number) => {
        setIsLoading(true);
        setSelectedEmailTemplateID(emailTemplateID);
        setShowEmailTemplateDialog(true);
    }, []);
    const columns: GridColDef[] = useMemo(() => GetEmailTemplateListColumns(getEmailTemplateCallback), [getEmailTemplateCallback]);
    const [queryOptions, setQueryOptions] = useState<EmailTemplateSearchRequest>(CreateDefaultEmailTemplateListSearchRequest(location));

    useEffect(() => {
        if (appUser?.token) {
            setIsLoading(true);

            SearchEmailTemplates(queryOptions, appUser.token)
                .then((response) => response.json())
                .then((data) => {
                    setRowData(data);

                    if(data && data.length > 0){
                        setRowCount(data[0].totalCount);
                    } else {
                        setRowCount(0);
                    }
                }).finally(() => setIsLoading(false));
        }
    }, [queryOptions, appUser?.token]);

    useEffect(() => {
        if (selectedEmailTemplateID) {
            setIsLoading(true);

            GetEmailTemplateDetail(selectedEmailTemplateID, appUser!.token)
            .then((response) => response.json())
            .then((data) => {
                setSelectedEmailTemplate(data);
            }).finally(() => setIsLoading(false));
        }
    }, [selectedEmailTemplateID, appUser]);

    const onPageChange = useCallback((newPage: number) => {
        setQueryOptions({...queryOptions, page: newPage});
    }, [queryOptions]);

    const onSortModelChange = useCallback((sortModel: GridSortModel) => {
        forEach(sortModel, (sortParam) => {
            setQueryOptions({...queryOptions, sortColumn: sortParam.field, sortDirection: sortParam.sort});
        });
    }, [queryOptions]);

    const childMenuContent = (<RightMenu>
        <List component="nav">
            <ListItem>
                <Typography variant="h6">Action Items</Typography>
            </ListItem>
            <ListItemButton onClick={() => setQueryOptions(CreateDefaultEmailTemplateListSearchRequest(location))} key="reset">
                Reset Filters
            </ListItemButton>
            <ListItem>
                <Typography variant="h6">Filters</Typography>
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    value={queryOptions.emailTemplateIDs}
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, emailTemplateIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetEmailTemplateTypeAheadMultiSearch(appUser?.token!, searchTerm)}
                    label="Email Templates"
                />
            </ListItem>
            <ListItem>
                <TypeAheadMulti
                    value={queryOptions.surveyTypeIDs}
                    onChange={(event: React.SyntheticEvent, newValue: NumericDropDownModel[]) => setQueryOptions({...queryOptions, surveyTypeIDs: newValue, page: 0})}
                    searchCallback={(searchTerm?: string) => GetAssessmentTypeTypeAheadSearch(appUser?.token!, searchTerm)}
                    label="Assessment Types"
                />
            </ListItem>
            <ListItem>
                <LanguageDropDownMulti
                    value={queryOptions.languageIDs}
                    onChange={(event: React.SyntheticEvent, newValue:NumericDropDownModel[]) => setQueryOptions({...queryOptions, languageIDs: newValue, page: 0})}
                    surveyTypeID={null}
                    isSurveyTypeAgnostic={true}
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel variant="standard">Email Types</InputLabel>
                    <Select
                        value={queryOptions.types}
                        multiple
                        onChange={(event: SelectChangeEvent<string[]>, child: React.ReactNode) => setQueryOptions({...queryOptions, types: event.target.value as string[], page: 0})}
                        label="Email Types"
                        fullWidth
                        variant="standard"                               
                    >
                        <MenuItem value="Authorization">Authorization</MenuItem>
                        <MenuItem value="Completion">Completion</MenuItem>
                        <MenuItem value="Completion DHTP">Completion DHTP</MenuItem>
                        <MenuItem value="Delegate">Delegate</MenuItem>
                        <MenuItem value="In Review">In Review</MenuItem>
                        <MenuItem value="Reminder">Reminder</MenuItem>
                        <MenuItem value="Test">Test</MenuItem>
                        <MenuItem value="Welcome">Welcome</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    </RightMenu>);

    return (
        <BaseLayout childMenu={childMenuContent}>
            
            <Grid sx={{display: 'flex', height: '100%'}}>
                <DataGrid
                    getRowId={(row: any) => row.emailTemplateID}
                    columns={columns}
                    rows={rowData}
                    disableColumnMenu
                    filterMode='server'
                    rowCount={rowCount}
                    pagination
                    paginationModel={{page: queryOptions.page, pageSize: queryOptions.pageSize}}
                    onPaginationModelChange={(model: GridPaginationModel) => onPageChange(model.page)}
                    paginationMode='server'
                    pageSizeOptions={[100]}
                    loading={isLoading}
                    sortingMode='server'
                    onSortModelChange={onSortModelChange}
                    slots={{
                        toolbar: NoFilterToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            moduleName: 'EmailTemplates',
                            gridConfigName: 'emailTemplateGridState'
                        }
                    }}
                    disableRowSelectionOnClick={true}
                    onRowClick={(params: GridRowParams) => {
                        navigate(`${params.id}`, {
                            state: {
                                fromPath: location.pathname,
                                componentState: queryOptions
                            }
                        });
                    }}
                    sx={{
                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                           outline: 'none !important',
                        },
                    }}
                />
            </Grid>
            <EmailTemplateDialog
                open={ showEmailTemplateDialog }
                selectedEmailTemplate={ selectedEmailTemplate }
                isLoading={ isLoading }
                onClose={ () => {
                    setShowEmailTemplateDialog(false);
                }}
            />
        </BaseLayout>
    );
}

export default EmailTemplateList;
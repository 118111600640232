import BaseLayout from "../../layout/BaseLayout.component";
import React, { useEffect, useState } from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import { Card, CardContent, CardHeader, Chip, Grid, Stack, Typography } from "@mui/material";
import { ReleaseNoteDetailModel } from "../ReleaseNotes/ReleaseNote.models";
import { HomeSearchReleaseNotes } from "../ReleaseNotes/ReleaseNote.service";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const { appUser } = useApiAuthorization();
    const [ releaseNoteData, setReleaseNoteData] = React.useState<ReleaseNoteDetailModel[]>();
    const navigate = useNavigate();
    let [redirect, setRedirect] = useState<string|null>(); 

    useEffect(() => {
        if(window.localStorage.getItem('redirect')){
            setRedirect(window.localStorage.getItem('redirect'));
            window.localStorage.removeItem('redirect');
        }
    }, []);

    useEffect(() => {
        if(redirect){
            navigate(redirect);
        }    
    }, [redirect, navigate]);

    React.useEffect(() => {
        if(appUser?.token){
            HomeSearchReleaseNotes(appUser.token)
            .then((response) => response.json())
            .then((data) => {
                setReleaseNoteData(data);
            });
        }
    }, [appUser?.token]);

    return (
        <BaseLayout>
            <div>
                { appUser && (
                    <>
                        <Card>                
                            <CardHeader title={`Welcome ${appUser?.fullName}!`} />
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }}>
                                    We're thrilled to have you here. This is your central hub for all HIMSS maturity model and DHI assessments. Whether you're an experienced user or a new member of our team, this page is designed to help you navigate through our database of health organizations and create and manage assessments up to a validation.
                                </Typography>
                                <Typography sx={{ mb: 1.5 }}>
                                    This is just the start. Our goal is to create a seamless and efficient experience for you, ensuring that you have everything at your fingertips to work collaboratively and effectively.
                                </Typography>
                                <Typography>
                                    If you have any questions, suggestions, or feedback, please don't hesitate to reach out. Thank you for being a valuable part of HIMSS. Let's achieve great things together!
                                </Typography>
                            </CardContent>
                        </Card>
                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            sx={{ 
                                mt: 5, 
                                flexWrap: "wrap",
                                rowGap: "10px" ,
                                marginBottom: "20px"
                            }}
                        >
                            <Chip
                                label="#Curiosity"
                                color="primary"
                                sx={{fontWeight:'bold'}}
                            />
                            <Chip
                                label="#Belonging"
                                color="secondary"
                                sx={{fontWeight:'bold'}}
                            />
                            <Chip
                                label="#Integrity"
                                color="warning"
                                sx={{fontWeight:'bold'}}
                            />
                            <Chip
                                label="#Simplicity"
                                color="info"
                                sx={{fontWeight:'bold'}}
                            />
                            <Chip
                                label="#Tenacity"
                                color="success"
                                sx={{fontWeight:'bold'}}
                            />
                        </Stack>
                        <Grid container spacing={2}>
                            {releaseNoteData && releaseNoteData.map((releaseNote, i) => (
                                <Grid item xs={12} key={i}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant='h5'>
                                                {releaseNote.title}
                                            </Typography>
                                            <Typography component='div' dangerouslySetInnerHTML={{__html:releaseNote.details}} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </div>
        </BaseLayout>
    )
};

export default Home;
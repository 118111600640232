import { GridColDef } from "@mui/x-data-grid";
import { ApiBaseRoute } from "../../api_helper";
import { AARSearchRequest } from "./AAR.models";
import { Link } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { GetReportURL } from "../Accounts/Account.service";

export const GetAARListColumns = (): GridColDef[] => {
    return [
        {
            field: "accountName",
            headerName: "Account",
            flex: 2,
            filterable: false,
        },
        {
            field: "uniqueID",
            headerName: "Account ID",
            flex: 1,
            filterable: false,
        },
        {
            field: "surveyType",
            headerName: "Assessment Type",
            flex: 1,
            filterable: false,
        },
        {
            field: "surveyID",
            headerName: "Assessment ID",
            flex: 1,
            filterable: false,
        },
        {
            field: "internalUrl",
            headerName: "Report Link",
            flex: 1,
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) =>
                GetReportURL(params.row.surveyType, params.row.uniqueID),
            renderCell: (params) => {
                return (
                    <Link
                        href={GetReportURL(
                            params.row.surveyType,
                            params.row.uniqueID
                        )}
                        target="_blank"
                    >
                        <OpenInNewIcon />
                    </Link>
                );
            },
        },
        {
            field: "accountUrl",
            headerName: "Account Link",
            flex: 1,
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `/accounts/${params.row.uniqueID}`,
            renderCell: (params) => {
                return (
                    <Link
                        href={`/accounts/${params.row.uniqueID}`}
                        target="_blank"
                    >
                        <OpenInNewIcon />
                    </Link>
                );
            },
        },
    ];
};

export const SearchAARs = (
    request: AARSearchRequest,
    token: string | undefined
): Promise<Response> => {
    let requestCopy = {
        ...request,
        facilityTypeIDs: request.facilityTypeIDs.map((x) => x.value),
        surveyTypeIDs: request.surveyTypeIDs.map((x) => x.value),
        facilityIDs: request.facilityIDs.map((x) => x.value),
    };
    requestCopy.page += 1;
    return fetch(ApiBaseRoute + "Accounts/AccountReports/Search", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            accept: "application/json",
        },
        body: JSON.stringify(requestCopy),
    });
};
